import * as React from "react";
import HeaderMenu from "../common/HeaderMenu.js";
import { useHistory } from "react-router-dom";
import CheckoutProgressBar from "./CheckoutProgressBar";
import CustomerAmountDetails from "./CustomerAmountDetails";
import {
  EDIT_PRODUCT_QUANTITY,
  SET_TOTAL_AMOUNT,
} from "../../constants/actionTypes";
import minus from "../../assert/customer-design/minus.svg";
import plus from "../../assert/customer-design/plus.svg";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

const CustomerCart = (props) => {
  const history = useHistory();
  const { applicationState, dispatch } = props;
  const { cartDetails, productList, config } = applicationState;
  const [tempCart, setTempCart] = React.useState(cartDetails);
  const [subTotalAmount, setSubTotalAmount] = React.useState("");
  const [finalVatAmount, setFinalVatAmount] = React.useState("0");
  const [totalAmount, setTotalAmount] = React.useState("");
  const [shippingCost, setShippingCost] = React.useState("9.95");
  const [errorMessage, setErrorMessage] = React.useState("");

  React.useEffect(() => {
    if (tempCart) {
      setTempCart(cartDetails);
      settingAmountDetails(cartDetails, shippingCost, "");
    }
  }, [cartDetails]);

  const removeItemFromCart = (e, productid) => {
    e.preventDefault();
    const productlistArray = [];
    const filtered = tempCart.filter(
      (cartItem) => cartItem.productid !== productid
    );
    productList.map((rowdetail) => {
      let productListObject = Object.assign(rowdetail);
      if (rowdetail.productid === productid) {
        productListObject = {
          ...rowdetail,
          quantity: 0,
        };
      }
      productlistArray.push(productListObject);
    });

    setTempCart(filtered);
    settingAmountDetails(filtered, shippingCost, "");
    dispatch({
      type: EDIT_PRODUCT_QUANTITY,
      payload: productlistArray,
      cartDetails: filtered,
    });
    window.sessionStorage.setItem("cart", JSON.stringify(filtered));
    setErrorMessage("");
  };

  const updateProductQuantity = (e, product, btnQty = 0) => {
    let qty = e?.target?.value ?? btnQty;

    const { productList } = applicationState;
    const productlistArray = [];
    const productBeingConsidered = productList.find(
      (totalProduct) => totalProduct.productid === product.productid
    );

    const productQty = parseInt(qty);

    if (productQty <= 0) {
      // window.allert("Product Quantity Can't Be Lesser Then 0");
      return setErrorMessage("Product quantity can't be lesser than 0!");
    } else if (!productBeingConsidered.warehouse["Online-Warehouse"]) {
      return setErrorMessage("product is out of stock");
    } else if (
      productQty > productBeingConsidered.warehouse["Online-Warehouse"]
    ) {
      return setErrorMessage(
        `Product quantity can't be more than ${productBeingConsidered.warehouse["Online-Warehouse"]}`
      );
    } else {
      setErrorMessage("");
    }
    console.log("tempCart", tempCart);

    tempCart.forEach((productInCart) => {
      if (productInCart.productid === product.productid) {
        productInCart.quantity = qty ? Number(qty) : qty;
        if (productInCart.quantity < 0) {
          productInCart.quantity = productInCart.quantity * -1;
        }
        console.log("total VAT", productInCart.vat);
        console.log("total VAT for considered", productBeingConsidered.vat);
        // productInCart.vat = qty
        //   ? Number(qty) * Number(productInCart.vat)
        //   : productInCart.vat;
        if (productInCart?.vat === 0) {
          console.log("productInCart VAT", productInCart.vat);
        } else {
          productInCart.vat = qty
            ? Number(qty) * Number(productInCart.salepriceperunit) * 0.2
            : productInCart.vat;
        }
      }
    });
    productList.map((rowdetail) => {
      let productListObject = Object.assign(rowdetail);
      if (rowdetail.productid === product.productid) {
        productListObject = {
          ...rowdetail,
          quantity: qty ? Number(qty) : 0,
        };
      }
      productlistArray.push(productListObject);
    });
    setTempCart(tempCart);
    settingAmountDetails(tempCart, shippingCost, "");
    dispatch({
      type: EDIT_PRODUCT_QUANTITY,
      payload: productlistArray,
      cartDetails: tempCart,
    });

    window.sessionStorage.setItem("cart", JSON.stringify(tempCart));
  };

  const isBeSpokenProduct = (finalProduct) => {
    return applicationState?.customerDetails?.priceKeyValue?.filter(
      (product) => product.productId === finalProduct.productid
    );
  };

  const calSubTotal = (finalProduct) => {
    const isBulkDiscount = bulkDiscount(finalProduct);
    const beSpokenProduct = isBeSpokenProduct(finalProduct);
    if (beSpokenProduct.length === 0 && isBulkDiscount != "N/A") {
      return Number(
        (finalProduct?.salepriceperunit - finalProduct[isBulkDiscount]) *
          finalProduct.quantity
      );
    } else {
      return Number(finalProduct.quantity * finalProduct?.salepriceperunit);
    }
  };

  const calBulkVat = (finalProduct) => {
    const isBulkDiscount = bulkDiscount(finalProduct);
    const beSpokenProduct = isBeSpokenProduct(finalProduct);
    if (
      beSpokenProduct.length === 0 &&
      finalProduct.vat === 0 &&
      isBulkDiscount != "N/A"
    ) {
      return Number(finalProduct.quantity * finalProduct?.vat);
    } else if (
      beSpokenProduct.length === 0 &&
      finalProduct.vat > 0 &&
      isBulkDiscount != "N/A"
    ) {
      const discountedvat =
        (finalProduct?.salepriceperunit - finalProduct[isBulkDiscount]) * 0.2;
      return Number(finalProduct.quantity * discountedvat);
    } else {
      return Number(finalProduct?.vat);
    }
  };

  const bulkDiscount = (item) => {
    if (item.morethan50discount && item.quantity > 50)
      return "morethan50discount";
    else if (item.morethan20discount && item.quantity > 20)
      return "morethan20discount";
    else return "N/A";
  };

  const settingAmountDetails = (updatedCart, shippingCost, option) => {
    let subTotalValue = 0;
    let vatAmount = 0;

    if (updatedCart && updatedCart.length > 0) {
      const totalArray = updatedCart?.map((prod) => calSubTotal(prod));
      const vatArray = updatedCart?.map((prod) => calBulkVat(prod));
      const reducer = (previousValue, currentValue) =>
        previousValue + currentValue;
      subTotalValue = totalArray.reduce(reducer);
      vatAmount = vatArray.reduce(reducer);
      setSubTotalAmount(subTotalValue);
      setFinalVatAmount(vatAmount);
      setTotalAmount(
        (subTotalValue + vatAmount + Number(shippingCost)).toFixed(2)
      );
      setShippingCost(shippingCost);
    } else {
      setSubTotalAmount(0);
      setFinalVatAmount(0);
      setTotalAmount(0);
      setShippingCost("0");
    }
    dispatch({
      type: SET_TOTAL_AMOUNT,
      payload: {
        shippingCost,
        subTotalAmount: subTotalValue,
        totalVatAmount: vatAmount,
        totalAmount: Number(
          subTotalValue + vatAmount + Number(shippingCost)
        ).toFixed(2),
        warehouseattr: option,
      },
    });
  };

  const isNextButtonDisabled = () => {
    let disableNextButton = false;
    disableNextButton = !(tempCart && tempCart.length > 0);
    tempCart.forEach((productInCart) => {
      if (!productInCart.quantity) {
        disableNextButton = true;
      }
    });
    return disableNextButton;
  };

  const onIncrement = (qty, prod) => {
    const incrementQuantity = Number(qty) + 1;

    if (parseInt(incrementQuantity) > prod.warehouse["Online-Warehouse"]) {
      return toast(
        `Product quantity can not be more than ${prod.warehouse["Online-Warehouse"]}`
      );
    }
    updateProductQuantity(0, prod, incrementQuantity);
  };

  const onDecrement = (quantity, prod) => {
    const decrementQuantity = Number(quantity) <= 0 ? 0 : Number(quantity) - 1;
    if (parseInt(decrementQuantity) < 0) {
      return toast(`Product quantity can not be less than 0`);
    }
    updateProductQuantity(0, prod, decrementQuantity);
  };

  return (
    <div>
      <div>
        <HeaderMenu dispatch={dispatch} cartCount={tempCart.length} />
      </div>
      <ToastContainer />
      <div class="template_cart mt_30 mb_50">
        <div class="container">
          <CheckoutProgressBar
            progressItem="Cart"
            userType={config?.userType}
          />

          <div class="cart__content flex justify-content-between flex-wrap">
            <div class="cart__left mb_30">
              <h3 class="title_name">My Cart</h3>
              <div class="visible-xs bg_gredient cartFilter">
                <svg
                  style={{ position: "relative", top: "2px" }}
                  width="16"
                  height="18"
                  viewBox="0 0 16 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0001 9V16.88C10.0401 17.18 9.94013 17.5 9.71013 17.71C9.61762 17.8027 9.50773 17.8762 9.38675 17.9264C9.26578 17.9766 9.1361 18.0024 9.00513 18.0024C8.87416 18.0024 8.74448 17.9766 8.62351 17.9264C8.50253 17.8762 8.39264 17.8027 8.30013 17.71L6.29013 15.7C6.18113 15.5933 6.09824 15.4629 6.04794 15.319C5.99763 15.175 5.98127 15.0213 6.00013 14.87V9H5.97013L0.210131 1.62C0.0477393 1.41153 -0.0255351 1.14726 0.00631899 0.88493C0.0381731 0.622602 0.172566 0.383546 0.380131 0.22C0.570131 0.08 0.780131 0 1.00013 0H15.0001C15.2201 0 15.4301 0.08 15.6201 0.22C15.8277 0.383546 15.9621 0.622602 15.9939 0.88493C16.0258 1.14726 15.9525 1.41153 15.7901 1.62L10.0301 9H10.0001Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div class="cart__item__content">
                {tempCart &&
                  tempCart?.map((product) => (
                    <div class="cart__item" key={product.productid}>
                      <div class="inner_d cart_mob_delete visible-xs">
                        <button
                          onClick={(e) =>
                            removeItemFromCart(e, product.productid)
                          }
                          class="deleteCartItem"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7 21C6.45 21 5.97933 20.8043 5.588 20.413C5.19667 20.0217 5.00067 19.5507 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8043 20.021 18.413 20.413C18.0217 20.805 17.5507 21.0007 17 21H7ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
                              fill="#FF0000"
                            />
                          </svg>
                        </button>
                      </div>
                      <div class="inner_d">
                        <div class="label_d">{product.brand}</div>
                        <div class="info_d">
                          {product.producttype} {product.productdesc}
                        </div>
                      </div>
                      <div class="inner_d align-items-center">
                        <div class="label_d">Quantity</div>
                        <div class="info_d">
                          <div class="cart__quantity quantity">
                            <button class="cart__quantity-minus qty_minus">
                              <button
                                className="pro__quantity-minus qty_minus"
                                onClick={(event) =>
                                  onDecrement(product.quantity, product)
                                }
                                disabled={product.quantity <= 0}
                              >
                                <img src={minus} alt="minus" />
                              </button>
                            </button>
                            <input
                              type="number"
                              className="cart-input"
                              name="quantity"
                              id={`quantity-${product.productid}`}
                              value={product.quantity}
                              onChange={(e) =>
                                updateProductQuantity(e, product)
                              }
                            />
                            <button
                              className="pro__quantity-plus qty_plus"
                              onClick={(val) =>
                                onIncrement(product.quantity, product)
                              }
                              disabled={
                                product.quantity ===
                                Number(product.numberofstock)
                              }
                            >
                              <img src={plus} alt="plus" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="inner_d">
                        <div class="label_d">Amount</div>
                        <div class="info_d">
                          £ {Number(product.salepriceperunit).toFixed(2)}
                        </div>
                      </div>
                      <div class="inner_d setZeroMargin">
                        <div class="label_d">VAT</div>
                        <div class="info_d">
                          £ {Number(calBulkVat(product)).toFixed(2)}
                        </div>
                      </div>
                      {isBeSpokenProduct(product).length === 0 &&
                        bulkDiscount(product) != "N/A" && (
                          <div className="h6">
                            <span className="bulk-discount mrr-5">{`Bulk Discount Applied: ${bulkDiscount(
                              product
                            )}`}</span>
                          </div>
                        )}
                      <div class="action_btn text-center hidden-xs">
                        <button
                          onClick={(e) =>
                            removeItemFromCart(e, product.productid)
                          }
                          class="btn pink-btn"
                        >
                          <svg
                            width="12"
                            height="14"
                            style={{ marginRight: "3px" }}
                            viewBox="0 0 12 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.5 12.25C1.5 13.075 2.175 13.75 3 13.75H9C9.825 13.75 10.5 13.075 10.5 12.25V3.25H1.5V12.25ZM11.25 1H8.625L7.875 0.25H4.125L3.375 1H0.75V2.5H11.25V1Z"
                              fill="white"
                            />
                          </svg>{" "}
                          Delete
                        </button>
                      </div>
                    </div>
                  ))}
                {errorMessage ? (
                  <p className="text-error">{errorMessage}</p>
                ) : null}
              </div>
            </div>
            <div class="cart__side mb_30">
              <CustomerAmountDetails
                subTotalAmount={subTotalAmount}
                finalVatAmount={finalVatAmount}
                totalAmount={totalAmount}
                shippingCost={shippingCost}
                changeShippingCost={(newShippingCost, option) =>
                  settingAmountDetails(tempCart, newShippingCost, option)
                }
                dispatch={dispatch}
              />
            </div>
          </div>
          <div class="action_btn text-center">
            <button
              className="btn grey-btn m_8"
              type="submit"
              onClick={() => {
                history.push("/producttypes");
              }}
            >
              Back
            </button>
            <button
              className="btn green-btn m_8"
              type="submit"
              name="next"
              id="next"
              onClick={() => {
                history.push("/customershipping_info");
              }}
              disabled={isNextButtonDisabled()}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomerCart;
