import * as React from "react";
import { STORAGE_KEYS } from "../../../constants/common";

const CustomerAmountDetails = (props) => {
  const {
    dispatch,
    cartDetails,
    cancelCashPaid,
    cashPaidAmount,
    subTotalAmount,
    finalVatAmount,
    totalAmount,
    shippingCost,
    changeShippingCost,
    promocodeDiscount,
    shippingDisabled,
    discountAmount,
    isBulkDiscount
  } = props;

  const setAttributeValue = (e) => {
    const warehouse = localStorage.getItem(STORAGE_KEYS.WALKING_SELECTED_WAREHOUSE);
    changeShippingCost(e.target.value, warehouse)
  }

  let msg = "";
  if (shippingCost == 9.95) {
    msg = "Discount applied £ 9.95";
  } else {
    msg = "Offer only on shipping £ 9.95";
  }
  const promoCodeTitle =  promocodeDiscount && promocodeDiscount === 619 ? msg :  `${promocodeDiscount}` ;

  return (
    <>
      <fieldset>
        <h2 className="fs-title">Order Summary</h2>
        <div
          className="form-card order-bg">
          <div className="h5">
            <span>Subtotal</span>
            <span style={{ float: "right" }}>{`£${Number(subTotalAmount).toFixed(2)}`}</span>
          </div>
          <div className="h6">
            <span>VAT</span>
            <span style={{ float: "right" }}>{`£${Number(finalVatAmount).toFixed(2)}`}</span>
          </div>
          {
            cashPaidAmount ?
              <div className="h6">
                <span>Cash Paid</span>
                <span style={{ float: "right" }}><i title="cancel" className="cash-paid-cross-button" onClick={() => cancelCashPaid()}>X</i>{`£${Number(cashPaidAmount).toFixed(2)}`}</span>
              </div> : null
          }
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 p-0">
              <label className="h6" style={{ verticalAlign: "sub" }}>
                Shipping Cost
              </label>
            </div>
            <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12 p-0">
              <select
                className="form-select"
                id="shipping"
                name="shipping"
                onChange={(e) => setAttributeValue(e)}
                value={shippingCost}
                disabled={shippingDisabled ? true : false}
              >

                <option data-warehouse='Click and Collect' value="0">Click and collect £ 0</option> 
                <option data-warehouse='Next day delivery' value="9.95">Next day delivery £ 9.95</option>
                <option data-warehouse='Nextday by 12pm' value="15.95">Next day by 12pm £ 15.95</option>
                <option data-warehouse='Nextday by 10:30am' value="19.95">Next day by 10.30am £ 19.95</option>
                <option data-warehouse='Saturday Delivery' value="14">Saturday Delivery - £ 14
                  (If delivery isn't completed we won't be responsible for any fridge lines)</option>

              </select>
            </div>
          </div>
          {isBulkDiscount && <div className="row">
            <label>Bulk Discount Applied</label>
          </div>}
          {promocodeDiscount && <div className="h6 container-promo">
            <span style={{ color: "black" }}>Discount ({Number(promoCodeTitle).toFixed(2)})</span>
            {promoCodeTitle != "Discount applied £ 9.95" && <span style={{ float: "right", color: "black" }}>{`- £${Number(discountAmount).toFixed(2)}`}</span>}
          </div>}
          <div className="separator"></div>
          <div className="h5">
            <span><strong>Total</strong></span>
            <span style={{ float: "right" }}>{`£${Number(totalAmount).toFixed(2)}`}</span>
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default CustomerAmountDetails;
